import {
  Box,
  Center,
  Heading,
  HStack,
  IconButton,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Switch,
  Text
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import ChartZoomButtons from 'components/ChartZoomButtons'
import LBPairDistributionChart from 'components/LBPairDistributionChart'
import useChainId from 'hooks/useChainId'
import React, { useMemo, useState } from 'react'
import { SettingsIcon } from 'theme/icons'
import { LBPairUserBalances } from 'types/poolV2'
import { convertLBPositionToLiquidityChartData } from 'utils/poolV2'
import { wrappedCurrency } from 'utils/wrappedCurrency'

interface UserLiquidityChartProps {
  activeBinId: number
  binStep: string
  isLoadingUserBalances: boolean
  isPriceRatioInversed: boolean
  currency0?: Currency
  currency1?: Currency
  rewardedBins?: number[]
  userBalances?: LBPairUserBalances
}

const UserLiquidityChart = ({
  activeBinId,
  binStep,
  currency0,
  currency1,
  isLoadingUserBalances,
  isPriceRatioInversed,
  rewardedBins,
  userBalances
}: UserLiquidityChartProps) => {
  const chainId = useChainId()

  const [distributionRadius, setDistributionRadius] = useState(100)
  const [isShowRewardedRangeToggled, setIsShowRewardedRangeToggled] =
    useState(true)
  const highlightedBins = isShowRewardedRangeToggled ? rewardedBins : undefined

  const data = useMemo(() => {
    const token0 = wrappedCurrency(currency0, chainId)
    const token1 = wrappedCurrency(currency1, chainId)
    return convertLBPositionToLiquidityChartData({
      activeBinId,
      binStep,
      highlightedBins,
      radius: distributionRadius,
      token0,
      token1,
      userBalances
    })
  }, [
    currency0,
    currency1,
    binStep,
    userBalances,
    activeBinId,
    chainId,
    highlightedBins,
    distributionRadius
  ])

  const maxRadius = useMemo(() => {
    if (!userBalances?.positions?.length) return 0
    const firstBin = userBalances.positions[0]
    const lastBin = userBalances.positions[userBalances.positions.length - 1]
    return Math.max(
      Math.abs(activeBinId - firstBin),
      Math.abs(activeBinId - lastBin)
    )
  }, [userBalances?.positions, activeBinId])

  const isZoomButtonNeeded = useMemo(() => {
    if (!userBalances?.positions?.length) return false

    return distributionRadius < maxRadius
  }, [userBalances?.positions, distributionRadius, maxRadius])

  const isOutOfRange = userBalances
    ? !userBalances.positions.some((binId) => binId === activeBinId)
    : false

  return data.length === 0 || isLoadingUserBalances ? (
    <Box w="full">
      <Heading size="md" mb={2}>
        <Trans>My Liquidity</Trans>
      </Heading>
      <Center h="216px">
        {isLoadingUserBalances ? (
          <Spinner size="sm" color="textSecondary" />
        ) : (
          <Text fontSize="sm" color="textSecondary">
            <Trans>You have no liquidity in this pool</Trans>
          </Text>
        )}
      </Center>
    </Box>
  ) : (
    <LBPairDistributionChart
      title={t`My Liquidity`}
      headingRightElement={
        <HStack>
          <Popover>
            <PopoverTrigger>
              <IconButton
                aria-label="my-liquidity-chart-settings"
                variant="ghost"
                icon={<SettingsIcon />}
                size="sm"
              />
            </PopoverTrigger>
            <PopoverContent bg="bgElement">
              <PopoverCloseButton />
              <PopoverHeader>Chart Settings</PopoverHeader>
              <PopoverBody>
                <HStack>
                  <Switch
                    colorScheme="accent"
                    size="lg"
                    isChecked={isShowRewardedRangeToggled}
                    onChange={() =>
                      setIsShowRewardedRangeToggled((curr) => !curr)
                    }
                  />
                  <Box as="span" fontSize="sm" textColor="textSecondary">
                    <Trans>Show rewarded range</Trans>
                  </Box>
                </HStack>
              </PopoverBody>
            </PopoverContent>
          </Popover>

          {isOutOfRange ? (
            <HStack spacing={2}>
              <Text fontSize="sm" textColor="yellow.400" fontWeight="bold">
                <Trans>Out of position</Trans>
              </Text>
              <Box boxSize={2} borderRadius="full" bg="yellow.400" />
            </HStack>
          ) : null}
        </HStack>
      }
      bottomRightElement={
        isZoomButtonNeeded ? (
          <ChartZoomButtons
            minValue={20}
            maxValue={maxRadius}
            value={distributionRadius}
            step={50}
            onChange={setDistributionRadius}
          />
        ) : undefined
      }
      bottomRightElementProps={{ bottom: 12, right: { base: 0, md: 4 } }}
      data={data}
      currency0={currency0}
      currency1={currency1}
      isPriceRatioInversed={isPriceRatioInversed}
      highlightedBins={highlightedBins}
      footer={
        isOutOfRange ? (
          <Text
            fontSize="sm"
            textColor="textSecondary"
            mt={4}
            textAlign="center"
          >
            <Trans>
              Your liquidity is out of position, rebalance to Active Bin to earn
              trading fees.
            </Trans>
          </Text>
        ) : undefined
      }
    />
  )
}

export default UserLiquidityChart
