import { Division, DIVISIONS } from 'pages/MoeJourney/constants'

export const getDivisionInfo = (rank: number): Division => {
  if (rank === 0) {
    return DIVISIONS[DIVISIONS.length - 1]
  }

  let cumulativeSize = 0
  for (const division of DIVISIONS) {
    cumulativeSize += division.size
    if (division.size === 0 || rank <= cumulativeSize) {
      return division
    }
  }

  return DIVISIONS[DIVISIONS.length - 1]
}

export const getRankInDivision = (rank: number): number => {
  let cumulativeSize = 0
  for (const division of DIVISIONS) {
    if (division.size === 0 || rank <= cumulativeSize + division.size) {
      return rank - cumulativeSize
    }
    cumulativeSize += division.size
  }
  return rank - cumulativeSize
}
