import { useMutation } from '@tanstack/react-query'
import { useDexbarnPost } from 'hooks/useDexbarn'
import { useAccount } from 'wagmi'

interface UsePostOptInProps {
  epoch: number
}

const usePostOptIn = ({ epoch }: UsePostOptInProps) => {
  const { address } = useAccount()
  const postOptIn = useDexbarnPost(
    `/v1/moe-points/epochs/${epoch}/users/${address}/opt-in`
  )

  return useMutation({
    mutationFn: ({ signature }: { signature: string }) =>
      postOptIn({
        signature
      })
  })
}

export default usePostOptIn
