import { MerchantMoeChainId } from 'constants/chains'
import { Point } from 'types/pool'

import { mETH } from './swap'

export const getPoolPoints = (
  tokenX: string,
  tokenY: string,
  pairAddress: string,
  type: 'v1' | 'v2'
): Point[] => {
  const tX = tokenX.toLowerCase()
  const tY = tokenY.toLowerCase()

  const mETHAddress = mETH[MerchantMoeChainId.MANTLE].address.toLowerCase()
  const fBTCAddress = '0xC96dE26018A54D51c097160568752c4E3BD6C364'.toLowerCase()
  const wBTCAddress = '0xCAbAE6f6Ea1ecaB08Ad02fE02ce9A44F09aebfA2'.toLowerCase()

  const points: Point[] = []

  if (
    pairAddress.toLowerCase() ===
    '0x3d887CE4988fb46AEC6E0027171f65DB3526E5f1'.toLowerCase()
  ) {
    points.push('powder_20x_s2_stable')
  } else if (
    pairAddress.toLowerCase() ===
    '0xF0601AA87a7341a38034B49f9517dd3adC2DdeC4'.toLowerCase()
  ) {
    points.push('powder_30x_s2_stable')
  } else if (
    pairAddress.toLowerCase() ===
    '0x2612E3280ca8836F58173bF7EcC35e258Dc1b54B'.toLowerCase()
  ) {
    points.push('powder_30x_s2_volatile')
  } else if (
    [
      '0xFe99Af0CC615D46A4b5b48dfE0478FfE74B2027A'.toLowerCase(),
      '0x1f9d94c13C9d9a3b67d62C15df9d135321C741ea'.toLowerCase(),
      '0x91C5aee46EBA5f6B38B962eE248b9cef04B05244'.toLowerCase()
    ].includes(pairAddress.toLowerCase())
  ) {
    points.push('powder_40x_s2_volatile')
  }

  if (
    ((tX === mETHAddress && tY === fBTCAddress) ||
      (tX === fBTCAddress && tY === mETHAddress)) &&
    type === 'v2'
  ) {
    points.push('fbtc_4x')
  }

  if (
    (tX === wBTCAddress && tY === fBTCAddress) ||
    (tX === fBTCAddress && tY === wBTCAddress)
  ) {
    points.push('fbtc_2x')
  }

  return points
}
