import { useQuery } from '@tanstack/react-query'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { MoeJourneyIsOptedInResponse } from 'types/dexbarn'
import { useAccount } from 'wagmi'

interface UseGetIsOptedInProps {
  epoch: number
}

const useGetIsOptedIn = ({ epoch }: UseGetIsOptedInProps) => {
  const { address } = useAccount()
  const fetchIsOptedIn = useDexbarnGet<MoeJourneyIsOptedInResponse>(
    `/v1/moe-points/epochs/${epoch}/users/${address}/opt-in`
  )

  return useQuery({
    enabled: !!address,
    queryFn: () => fetchIsOptedIn(),
    queryKey: ['MoeJourneyIsOptedIn', epoch, address],
    select: (data) => data.isOptIn
  })
}

export default useGetIsOptedIn
