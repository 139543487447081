import {
  Button,
  Flex,
  Heading,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack
} from '@chakra-ui/react'
import { Currency } from '@traderjoe-xyz/sdk-core'
import { ADD_LIQUIDITY_V2_DEFAULT_RADIUS } from 'constants/pool'
import useChainId from 'hooks/useChainId'
import useKeyPress from 'hooks/useKeyPress'
import React, { useState } from 'react'
import { RefreshIcon, ZapIcon } from 'theme/icons'
import { getPriceFromBinId } from 'utils/bin'
import { getMaxBinPerAddLiquidityBatch } from 'utils/getMaxBinPerBatch'

import PriceRadiusInputs from './PriceRadiusInputs'
import PriceRangeInputs from './PriceRangeInputs'

interface PriceRangeSelectionProps {
  activeBinId: number
  binRange: number[]
  binStep: number
  currency0: Currency
  currency1: Currency
  currencyPrice0: number | undefined
  currencyPrice1: number | undefined
  onBinRangeChange: (binRange: number[]) => void
  canUseRadiusInput?: boolean
  inversePriceRatios?: boolean
  max?: number
  maxBinPerBatch?: number
  min?: number
  onSelectRewardedRange?: () => void
  resetBinRange?: () => void
  showActiveBinIndicator?: boolean
  togglePriceRatiosClick?: () => void
}

const PriceRangeSelection = ({
  activeBinId,
  binRange,
  binStep,
  canUseRadiusInput = true,
  currency0,
  currency1,
  currencyPrice0,
  currencyPrice1,
  inversePriceRatios,
  max,
  maxBinPerBatch,
  min,
  onBinRangeChange,
  onSelectRewardedRange,
  resetBinRange,
  showActiveBinIndicator,
  togglePriceRatiosClick
}: PriceRangeSelectionProps) => {
  const chainId = useChainId()
  useKeyPress({
    onUp: togglePriceRatiosClick,
    targetKey: 't'
  })

  const nbBins = binRange[1] - binRange[0]
  const largeRangeDisclaimerText =
    nbBins > (maxBinPerBatch || getMaxBinPerAddLiquidityBatch(chainId))
      ? 'Adding liquidity to a large range requires more than one transaction.'
      : undefined

  const _getPriceFromBinId = (binId: number): string => {
    const binPrice = Number(
      getPriceFromBinId(
        binId,
        binStep,
        currency0.decimals,
        currency1.decimals,
        18
      ) ?? '0'
    )
    return (inversePriceRatios ? 1 / binPrice : binPrice).toFixed(8)
  }

  // tp and radius on parent to handle resetting on tab changes
  const [targetPrice, setTargetPrice] = useState(
    _getPriceFromBinId(activeBinId) // default at activeBinId,
  )
  const [targetBinId, setTargetBinId] = useState(activeBinId)
  const [radius, setRadius] = useState<string>(
    ADD_LIQUIDITY_V2_DEFAULT_RADIUS.toString()
  )

  const resetRanges = () => {
    resetBinRange?.()
    setRadius(ADD_LIQUIDITY_V2_DEFAULT_RADIUS.toString())
    setTargetPrice(_getPriceFromBinId(activeBinId))
    setTargetBinId(activeBinId)
  }

  return (
    <Tabs
      data-cy="price-range-selection"
      variant="solid-rounded"
      w="full"
      onChange={() => resetRanges()}
    >
      <VStack align="flex-start" w="full" spacing={4}>
        <Flex justify="space-between" align="center" w="full">
          <Heading size="md">Price</Heading>
          {canUseRadiusInput ? (
            <TabList>
              <Tab>By Range</Tab>
              <Tab>By Radius</Tab>
            </TabList>
          ) : null}
        </Flex>
        <TabPanels pt={2}>
          <TabPanel p={0}>
            <PriceRangeInputs
              activeBinId={showActiveBinIndicator ? activeBinId : undefined}
              currency0={currency0}
              currency1={currency1}
              binStep={binStep}
              min={min ?? activeBinId - ADD_LIQUIDITY_V2_DEFAULT_RADIUS}
              max={max ?? activeBinId + ADD_LIQUIDITY_V2_DEFAULT_RADIUS}
              largeRangeDisclaimerText={largeRangeDisclaimerText}
              binRange={binRange}
              inversePriceRatios={inversePriceRatios}
              onBinRangeChange={onBinRangeChange}
              currencyPrice0={currencyPrice0}
              currencyPrice1={currencyPrice1}
            />
          </TabPanel>
          {canUseRadiusInput ? (
            <TabPanel p={0}>
              <PriceRadiusInputs
                targetPrice={targetPrice}
                setTargetPrice={setTargetPrice}
                targetBinId={targetBinId}
                setTargetBinId={setTargetBinId}
                _getPriceFromBinId={_getPriceFromBinId}
                radius={radius}
                setRadius={setRadius}
                inversePriceRatios={inversePriceRatios}
                binStep={binStep}
                currency0={currency0}
                currency1={currency1}
                onBinRangeChange={onBinRangeChange}
                currencyPrice0={currencyPrice0}
                currencyPrice1={currencyPrice1}
              />
            </TabPanel>
          ) : null}
        </TabPanels>
        <HStack spacing={2} alignSelf="end">
          {onSelectRewardedRange ? (
            <Button
              variant="ghost"
              size="sm"
              colorScheme="purple"
              color="graphPurpleDark"
              leftIcon={<ZapIcon boxSize="16px" fill="graphPurpleDark" />}
              onClick={onSelectRewardedRange}
            >
              Select rewarded range
            </Button>
          ) : null}
          <Button
            variant="ghost"
            size="sm"
            colorScheme="accent"
            color="bgInteractive"
            leftIcon={<RefreshIcon boxSize="16px" fill="bgInteractive" />}
            onClick={resetRanges}
          >
            Reset price
          </Button>
        </HStack>
      </VStack>
    </Tabs>
  )
}

export default PriceRangeSelection
