import { BoxProps, Image } from '@chakra-ui/react'
import Canvas from 'assets/moeJourney/badges/canvas.png'
import Diamond from 'assets/moeJourney/badges/diamond.png'
import Emerald from 'assets/moeJourney/badges/emerald.png'
import Gilded from 'assets/moeJourney/badges/gilded.png'
import Golden from 'assets/moeJourney/badges/golden.png'
import Silk from 'assets/moeJourney/badges/silk.png'
import Velvet from 'assets/moeJourney/badges/velvet.png'
import Wool from 'assets/moeJourney/badges/wool.png'
import React from 'react'

import { DivisionType } from './constants'

interface JourneyBadgeProps {
  type: DivisionType
}

const JourneyBadge = ({ type, ...props }: JourneyBadgeProps & BoxProps) => {
  switch (type) {
    case 'emerald':
      return (
        <Image
          src={Emerald}
          boxSize={{ base: '44px', md: '90px' }}
          {...props}
        />
      )
    case 'diamond':
      return (
        <Image
          src={Diamond}
          boxSize={{ base: '44px', md: '90px' }}
          {...props}
        />
      )
    case 'golden':
      return (
        <Image src={Golden} boxSize={{ base: '44px', md: '90px' }} {...props} />
      )
    case 'gilded':
      return (
        <Image src={Gilded} boxSize={{ base: '44px', md: '90px' }} {...props} />
      )
    case 'velvet':
      return (
        <Image src={Velvet} boxSize={{ base: '44px', md: '90px' }} {...props} />
      )
    case 'silk':
      return (
        <Image src={Silk} boxSize={{ base: '44px', md: '90px' }} {...props} />
      )
    case 'wool':
      return (
        <Image src={Wool} boxSize={{ base: '44px', md: '90px' }} {...props} />
      )
    case 'canvas':
      return (
        <Image src={Canvas} boxSize={{ base: '44px', md: '90px' }} {...props} />
      )
  }
}

export default JourneyBadge
