import { useQuery } from '@tanstack/react-query'
import { TokenPrice } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'

import useChainId from './useChainId'
import { useDexbarnPost } from './useDexbarn'

interface UseGetTokensUsdPriceProps {
  enabled?: boolean
  tokenAddresses?: string[]
}

const useGetTokensUsdPrice = ({
  enabled = true,
  tokenAddresses
}: UseGetTokensUsdPriceProps) => {
  const chainId = useChainId()

  const sortedTokenAddresses = tokenAddresses?.sort()
  const postPrices = useDexbarnPost<TokenPrice[]>(
    `/v1/lb/tokens/${getDexbarnChainParam(chainId)}/prices`
  )

  return useQuery({
    enabled:
      !!sortedTokenAddresses && sortedTokenAddresses.length > 0 && enabled,
    queryFn: async () => {
      return await postPrices({ tokenAddresses: sortedTokenAddresses })
    },
    queryKey: ['GetTokensUsdPrice', sortedTokenAddresses],
    select: (data) => {
      return data.reduce(
        (acc, price) => {
          acc[price.tokenAddress] = Number(price.priceUsd)
          return acc
        },
        {} as Record<string, number>
      )
    }
  })
}

export default useGetTokensUsdPrice
