import { t } from '@lingui/macro'
import UnstakeTokenPanel from 'components/UnstakeTokenPanel'
import { MOE_STAKING } from 'constants/moe'
import { MOE_TOKEN } from 'constants/tokens'
import useChainId from 'hooks/useChainId'
import React from 'react'

interface UnstakeMoePanelProps {
  moeDepositedAmount?: string
  onUnstakeSuccess?: () => void
}

const UnstakeMoePanel = ({
  moeDepositedAmount,
  onUnstakeSuccess
}: UnstakeMoePanelProps) => {
  const chainId = useChainId()
  const moeToken = MOE_TOKEN[chainId]

  return (
    <UnstakeTokenPanel
      token={moeToken}
      stakingContractAddress={MOE_STAKING[chainId]}
      depositedAmount={moeDepositedAmount}
      onUnstakeSuccess={onUnstakeSuccess}
      footerText={t`Unstaking any amount of MOE will reset your veMOE balance.`}
    />
  )
}

export default UnstakeMoePanel
