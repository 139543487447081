import { Image } from '@chakra-ui/react'
import Canvas from 'assets/moeJourney/podium/canvas.png'
import Diamond from 'assets/moeJourney/podium/diamond.png'
import Emerald from 'assets/moeJourney/podium/emerald.png'
import Gilded from 'assets/moeJourney/podium/gilded.png'
import Golden from 'assets/moeJourney/podium/golden.png'
import Silk from 'assets/moeJourney/podium/silk.png'
import Velvet from 'assets/moeJourney/podium/velvet.png'
import Wool from 'assets/moeJourney/podium/wool.png'
import React from 'react'

import { DivisionType } from './constants'

interface PodiumImageProps {
  divisionType: DivisionType
}

const PodiumImage = ({ divisionType }: PodiumImageProps) => {
  switch (divisionType) {
    case 'emerald':
      return <Image src={Emerald} w="full" maxW="500px" />
    case 'diamond':
      return <Image src={Diamond} w="full" maxW="500px" />
    case 'golden':
      return <Image src={Golden} w="full" maxW="500px" />
    case 'gilded':
      return <Image src={Gilded} w="full" maxW="500px" />
    case 'velvet':
      return <Image src={Velvet} w="full" maxW="500px" />
    case 'silk':
      return <Image src={Silk} w="full" maxW="500px" />
    case 'wool':
      return <Image src={Wool} w="full" maxW="500px" />
    case 'canvas':
      return <Image src={Canvas} w="full" maxW="500px" />
  }
}

export default PodiumImage
