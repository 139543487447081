import {
  Button,
  Center,
  Flex,
  FlexProps,
  Heading,
  Hide,
  HStack,
  Image,
  Show,
  Skeleton,
  Spacer
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import MerchantMoeLogo from 'assets/merchant-moe-logo.png'
import ConnectButton from 'components/ConnectButton'
import HeaderSearchPanel from 'components/HeaderSearchPanel'
import Submenu from 'components/Submenu'
import UKDisclaimerBanner from 'components/UKDisclaimerBanner'
import { joeAirdrop, joeFarms, joeStake, joeVote } from 'constants/urls'
import useGetJourneymanRank from 'hooks/moeJourney/useGetJourneymanRank'
import JourneyBadge from 'pages/MoeJourney/JourneyBadge'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useOriginCountry } from 'state/settings/hooks'

import HeaderLink from './HeaderLink'

const HeaderContent = (): JSX.Element => {
  const hiddenItemsOnMobile = [joeFarms, joeStake, joeVote, joeAirdrop]

  const { data: journeymanData, isLoading: isLoadingJourneymanData } =
    useGetJourneymanRank()

  const navigate = useNavigate()
  const onMoeJourneyPointsClick = () => navigate('/journey')

  return (
    <Flex
      alignItems="center"
      w="full"
      maxW="1600px"
      margin="0 auto"
      p={4}
      gap={8}
      justify="space-between"
    >
      <Center
        as={Link}
        aria-label="Link to Home page"
        to="/"
        flexShrink={0}
        _hover={{ opacity: 0.5 }}
      >
        <HStack>
          <Image
            objectFit="contain"
            src={MerchantMoeLogo}
            transition="transform 0.3s ease"
            boxSize={12}
          />
        </HStack>
        <Heading fontSize="20px" fontWeight="black">
          Merchant Moe
        </Heading>
      </Center>

      <Hide below="lg">
        <Center>
          <HStack spacing={4} flexShrink={0} justify="center">
            <HeaderLink
              aria-label="Link to Trade page"
              to="trade"
              title={t`Trade`}
            />
            <HeaderLink aria-label="Link to Pool page" to="pool" title="Pool" />
            <Hide below="xl">
              {hiddenItemsOnMobile.map((item) => (
                <HeaderLink key={item.url} to={item.url} title={item.name} />
              ))}
            </Hide>
            <Show below="xl">
              <Submenu items={hiddenItemsOnMobile} />
            </Show>
          </HStack>
        </Center>
      </Hide>

      <Hide below="lg">
        <Flex gap={4} flexShrink={1} w="full">
          <Spacer />
          <Flex w="full" maxW="400px" flexShrink={2}>
            <HeaderSearchPanel />
          </Flex>
          <Skeleton isLoaded={!isLoadingJourneymanData} borderRadius="xl">
            <Button
              h="40px"
              onClick={onMoeJourneyPointsClick}
              borderRadius="10px"
              bg="bgCard"
              boxShadow="element"
              _hover={{ bg: 'hover' }}
              border="2px solid"
              borderColor="bgChart2"
              _dark={{ bg: 'bgSecondary' }}
              _active={{}}
              flexShrink={0}
              leftIcon={
                <JourneyBadge
                  type={journeymanData?.division.slug || 'canvas'}
                  boxSize="20px"
                />
              }
            >
              {journeymanData && journeymanData.rankInDivision > 0
                ? `${journeymanData.rankInDivision} of ${journeymanData.division.size}`
                : 'Trials'}
            </Button>
          </Skeleton>
          <ConnectButton />
        </Flex>
      </Hide>
    </Flex>
  )
}

const HeaderContainer = (props: FlexProps) => {
  const [isBorderVisible, setIsBorderVisible] = useState(false)

  useEffect(() => {
    const updatePosition = () => {
      setIsBorderVisible(window.scrollY > 0)
    }
    document.addEventListener('scroll', updatePosition)
    return () => {
      document.removeEventListener('scroll', updatePosition)
    }
  }, [])

  return (
    <Flex
      zIndex={11}
      position="sticky"
      width="full"
      top={0}
      minH="80px"
      flexDir="column"
      bg="bgPrimary"
      borderBottom="1px"
      borderBottomColor={isBorderVisible ? 'border' : 'transparent'}
      padding={{
        base: '1rem 0.25rem 1rem 1rem',
        md: '1rem 2rem',
        xs: '1rem'
      }}
      {...props}
    />
  )
}

const Header = () => {
  const { originCountryCode } = useOriginCountry()

  if (originCountryCode === 'GB') {
    return (
      <HeaderContainer>
        <UKDisclaimerBanner />

        <Hide below="md">
          <HeaderContent />
        </Hide>
      </HeaderContainer>
    )
  }

  return (
    <Hide below="md">
      <HeaderContainer>
        <HeaderContent />
      </HeaderContainer>
    </Hide>
  )
}

export default Header
