import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import Web3Button from 'components/Web3Button'
import usePostOptIn from 'hooks/moeJourney/usePostOptIn'
import useErrorToast from 'hooks/useErrorToast'
import React from 'react'
import { useSignMessage } from 'wagmi'

import PodiumImage from './PodiumImage'

interface MoeJourneyOptInViewProps {
  onOptInSuccess?: () => void
}

const MoeJourneyOptInView = ({ onOptInSuccess }: MoeJourneyOptInViewProps) => {
  const { signMessageAsync } = useSignMessage()
  const addErrorToast = useErrorToast()

  const {
    isOpen: isSuccessModalOpen,
    onClose: onCloseSuccessModal,
    onOpen: onOpenSuccessModal
  } = useDisclosure()

  const epoch = 1
  const { mutateAsync: postOptIn } = usePostOptIn({
    epoch
  })

  const handleOptIn = async () => {
    try {
      const message = `I opt-in for Journeyman Trials Epoch ${epoch}.`
      const signature = await signMessageAsync({ message })

      await postOptIn({ signature })

      onOpenSuccessModal()
    } catch (error) {
      addErrorToast(
        'Failed to opt-in',
        (error as Error).message,
        'journeyman-opt-in'
      )
    }
  }

  return (
    <VStack mt={{ base: 10, md: '100px' }} spacing={{ base: 4, md: 6 }}>
      <Modal
        isOpen={isSuccessModalOpen}
        onClose={() => {
          onCloseSuccessModal()
          onOptInSuccess?.()
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Welcome to the Journeyman Trails</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Explore Moe&apos;s exchange, earn your mantle, and rise through
              the ranks to become one of Moe&apos;s top journeymen. Your rank
              and division will update within 1 hour.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="accent"
              onClick={() => {
                onCloseSuccessModal()
                onOptInSuccess?.()
              }}
            >
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box
        borderRadius="2xl"
        overflow="hidden"
        border="5px solid"
        borderColor="bgInteractive"
      >
        <PodiumImage divisionType="canvas" />
      </Box>

      <Web3Button
        variant="solid"
        h={{ base: '50px', md: '60px' }}
        colorScheme="accent"
        bg="bgInteractive"
        textColor="white"
        size="lg"
        onClick={handleOptIn}
      >
        Sign up for Journeyman Trials
      </Web3Button>
    </VStack>
  )
}

export default MoeJourneyOptInView
