export const MOE_JOURNEY_REWARDER_ADDRESS =
  '0xaf38d5664ede982516aad35de91f9bfd2d161be0'

export const JOURNEYMAN_TRIALS_EPOCH = 1

export type DivisionType =
  | 'emerald'
  | 'diamond'
  | 'golden'
  | 'gilded'
  | 'velvet'
  | 'silk'
  | 'wool'
  | 'canvas'

export interface Division {
  size: number
  slug: DivisionType
}

export const DIVISIONS: Division[] = [
  {
    size: 25,
    slug: 'emerald'
  },
  {
    size: 75,
    slug: 'diamond'
  },
  {
    size: 150,
    slug: 'golden'
  },
  {
    size: 250,
    slug: 'gilded'
  },
  {
    size: 500,
    slug: 'velvet'
  },
  {
    size: 1500,
    slug: 'silk'
  },
  {
    size: 2500,
    slug: 'wool'
  },
  {
    size: 0,
    slug: 'canvas'
  }
]
