import {
  Box,
  Button,
  ButtonProps,
  Center,
  Flex,
  Heading,
  Hide,
  HStack,
  Image,
  Show,
  Text,
  VStack
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import JourneyImage from 'assets/home/journey.png'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { withFallback } from 'theme/chakra'

const HomeHighlightedCards = () => {
  const navigate = useNavigate()

  return (
    <Center mt={{ base: 4, md: '80px' }} mb={{ base: 4, md: '60px' }}>
      <HomeCard
        background="bgChart2"
        heading={t`Journeyman Trials`}
        description={t`Earn MNT rewards while exploring the Merchant Moe DEX.`}
        imageSrc={JourneyImage}
        actionButtonTitle={t`Enter the Trials`}
        onActionButtonClick={() => navigate('/journey')}
        boxShadow="0px 15px 50px 0px color(display-p3 0.6784 0.5216 0.8824 / 0.50)"
        buttonProps={{
          bg: 'deepPurple'
        }}
      />
    </Center>
  )
}

interface HomeCardProps {
  actionButtonTitle: string
  background: string
  boxShadow: string
  buttonProps: ButtonProps
  description: string
  heading: string
  imageSrc: string
  onActionButtonClick: () => void
}

const HomeCard = ({
  actionButtonTitle,
  background,
  boxShadow,
  buttonProps,
  description,
  heading,
  imageSrc,
  onActionButtonClick
}: HomeCardProps) => {
  return (
    <Box pos="relative" w="full" px={{ base: 4, md: 0 }}>
      <Hide below="md">
        <Image
          src={imageSrc}
          w="120px"
          pos="absolute"
          left="calc(50% - 60px)"
          top="-65px"
        />
      </Hide>

      <Flex
        bg={background}
        px={{ base: 4, md: 10 }}
        pb={{ base: 4, md: 10 }}
        pt={{ base: 4, md: '60px' }}
        borderRadius="20px"
        justify="space-between"
        flexDir="column"
        gap={5}
        align="flex-start"
        boxShadow={boxShadow}
        h="full"
      >
        <HStack justify="space-between" w="full">
          <VStack align={{ base: 'flex-start', md: 'center' }} w="full">
            <Heading color="white">{heading}</Heading>
            <Text
              color="white"
              opacity={0.8}
              fontSize={{ base: 'sm', md: 'md' }}
              textAlign={{ base: 'left', md: 'center' }}
            >
              {description}
            </Text>
          </VStack>

          <Show below="md">
            <Image src={imageSrc} w="100px" />
          </Show>
        </HStack>

        <Button
          borderRadius="10px"
          h={{ base: '48px', md: '60px' }}
          _hover={{ opacity: 0.8 }}
          _active={{ opacity: 0.6 }}
          w="full"
          onClick={onActionButtonClick}
          borderBottom="5px solid"
          borderBottomColor={withFallback(
            'color(display-p3 0 0 0 / 0.10)',
            'rgba(0, 0, 0, 0.10)'
          )}
          {...buttonProps}
        >
          <Text mt="5px" textColor="white">
            {actionButtonTitle}
          </Text>
        </Button>
      </Flex>
    </Box>
  )
}

export default HomeHighlightedCards
