import {
  Button,
  Center,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react'
import useMerkleProofRewards from 'hooks/moeJourney/useMerkleProofRewards'
import React, { useState } from 'react'

import { MOE_JOURNEY_REWARDER_ADDRESS } from './constants'

const MoeJourneyRewardReveal = () => {
  const [isOpen, setIsOpen] = useState(false)

  const handleReveal = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const { batchClaimRewards, claimableRewards, isClaiming, isLoading } =
    useMerkleProofRewards({
      market: '0x1111222233334444dEAddEAddEAdDeAdDeaDDEAd',
      rewarderAddress: MOE_JOURNEY_REWARDER_ADDRESS
    })
  const hasWon = claimableRewards && claimableRewards.length > 0

  let fmtRewards = '0 MNT'
  if (claimableRewards && claimableRewards.length > 0) {
    fmtRewards = claimableRewards
      .map((reward) => `${reward.tokenAmount} ${reward.token?.symbol}`)
      .join(', ')
  }

  if (!hasWon) {
    return null
  }

  return (
    <VStack mt="100px">
      <Heading size="lg">Previous Campaign</Heading>
      <Text fontSize="">
        You have unclaimed rewards from previous campaigns
      </Text>
      <Button
        mt={4}
        variant="solid"
        colorScheme="accent"
        onClick={handleReveal}
        size="lg"
      >
        Claim Rewards
      </Button>

      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {!isLoading
              ? hasWon
                ? 'Congratulations Journeyman!'
                : "That's a wrap Journeyman!"
              : 'Revealing Results'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoading ? (
              <Center flexDir="column" minH="200px">
                <Spinner />
                <Text mt={4}>Fetching rewards</Text>
              </Center>
            ) : (
              <Center flexDir="column">
                {hasWon ? (
                  <Text
                    my={8}
                    fontWeight="semibold"
                    fontSize="2xl"
                  >{`You've won ${fmtRewards}`}</Text>
                ) : (
                  <Text>
                    {`Moe's Journey SZN4 has ended. There are no additional rewards for you to claim. Stay tuned for more reward opportunities in future Moe campaigns!`}
                  </Text>
                )}
              </Center>
            )}
          </ModalBody>
          <ModalFooter>
            {!isLoading && hasWon ? (
              <Button
                w="full"
                size="xl"
                variant="primary"
                colorScheme="accent"
                onClick={batchClaimRewards}
                isLoading={isClaiming}
                isDisabled={!batchClaimRewards}
              >
                Claim Rewards
              </Button>
            ) : !isLoading ? (
              <Button variant="ghost" onClick={handleClose}>
                Close
              </Button>
            ) : null}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  )
}

export default MoeJourneyRewardReveal
