import { useQuery } from '@tanstack/react-query'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { JOURNEYMAN_TRIALS_EPOCH } from 'pages/MoeJourney/constants'
import { MoeJourneyUserRank } from 'types/dexbarn'
import { getDivisionInfo, getRankInDivision } from 'utils/journey'
import { useAccount } from 'wagmi'

const useGetJourneymanRank = () => {
  const { address } = useAccount()

  const fetchRank = useDexbarnGet<MoeJourneyUserRank>(
    `/v1/moe-points/epochs/${JOURNEYMAN_TRIALS_EPOCH}/users/${address}`
  )

  return useQuery({
    enabled: !!address,
    queryFn: fetchRank,
    queryKey: ['GetJourneymanRank', address],
    select: (data) => {
      const division = getDivisionInfo(data.rank)

      const totalUsersInDivision = division.size
        ? division.size
        : Math.max(data.participationCount - 5000, 0)

      return {
        division,
        rank: data.rank,
        rankInDivision: getRankInDivision(data.rank),
        totalUsers: data.participationCount,
        totalUsersInDivision
      }
    }
  })
}

export default useGetJourneymanRank
