import { Box, Button, Divider, HStack, Text, VStack } from '@chakra-ui/react'
import { toPng } from 'html-to-image'
import React, { useLayoutEffect, useRef, useState } from 'react'

import { Division } from './constants'
import PodiumImage from './PodiumImage'

interface PodiumSectionProps {
  division: Division
  rankInDivision: number
  totalUsersInDivision: number
}

const PodiumSection = ({
  division,
  rankInDivision,
  totalUsersInDivision
}: PodiumSectionProps) => {
  const [, setForceUpdate] = useState({})
  const podiumRef = useRef<HTMLDivElement>(null)
  const stackRef = useRef<HTMLDivElement>(null)

  const handleSaveImage = async () => {
    if (!podiumRef.current) return

    try {
      const dataUrl = await toPng(podiumRef.current, {
        cacheBust: true,
        pixelRatio: 1,
        quality: 1.0
      })

      const link = document.createElement('a')
      link.href = dataUrl
      link.download = 'journeyman.png'
      link.click()
    } catch (error) {
      console.error('Error generating image:', error)
    }
  }

  const handleTwitterShare = () => {
    const twitterText = `Just leveled up to a new division on @MerchantMoe_xyz's Journeyman Trials!\n\nThe rewards on this Moe's Rager campaign are HUGE so I'm fully locked in and max staking $MOE. They're dishing out a full 1 MILLION $MNT in just 2 months across bribe rewards and Trials.\n\nLet's RAGE.`

    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      twitterText
    )}`

    window.open(twitterUrl, '_blank')
  }

  useLayoutEffect(() => {
    const observer = new ResizeObserver(() => {
      setForceUpdate({})
    })

    if (stackRef.current) {
      observer.observe(stackRef.current)
    }

    return () => observer.disconnect()
  }, [])

  return (
    <VStack mt={{ base: 10, md: '100px' }} spacing={{ base: 4, md: 6 }}>
      <Box
        ref={podiumRef}
        borderRadius="2xl"
        overflow="hidden"
        border="5px solid"
        borderColor="bgInteractive"
        position="relative"
      >
        <PodiumImage divisionType={division.slug} />
        <VStack
          ref={stackRef}
          position="absolute"
          left={{
            base: stackRef.current
              ? `${132 + (132 - stackRef.current.offsetWidth) / 2}px`
              : '0',
            md: stackRef.current
              ? `${198 + (198 - stackRef.current.offsetWidth) / 2}px`
              : '0'
          }}
          top={{ base: '8px', md: '14px' }}
          align="center"
          spacing={0}
        >
          <Text
            color="#F1A3FD"
            fontSize={{ base: '20px', md: '30px' }}
            fontWeight="bold"
            fontFamily="DIN Condensed"
          >
            Rank #{rankInDivision}{' '}
            <Box as="span" fontSize={{ base: '16px', md: '20px' }}>
              of
            </Box>{' '}
            {totalUsersInDivision}
          </Text>

          <Divider borderColor="#F1A3FD" mt={{ base: -1, md: -2 }} mb={1} />
          <Text
            color="#F1A3FD"
            fontSize={{ base: '10px', md: '16px' }}
            fontFamily="DIN Condensed"
          >
            {`${
              division.slug.charAt(0).toUpperCase() + division.slug.slice(1)
            } Division`}
          </Text>
        </VStack>
      </Box>
      <HStack>
        <Button
          variant="solid"
          h={{ base: '50px', md: '60px' }}
          colorScheme="accent"
          bg="bgInteractive"
          textColor="white"
          size="lg"
          onClick={handleSaveImage}
        >
          Save Image
        </Button>
        <Button
          variant="solid"
          h={{ base: '50px', md: '60px' }}
          colorScheme="accent"
          bg="bgInteractive"
          textColor="white"
          size="lg"
          onClick={handleTwitterShare}
        >
          Share on Twitter
        </Button>
      </HStack>
    </VStack>
  )
}

export default PodiumSection
